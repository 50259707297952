import React, {FC} from 'react';

interface Props {
}

const Policy: FC<Props> = () => {
  return (
    <>
      <h2 className='title-32'>PRIVACY NOTICE</h2>
      {/*<div className='bold'>Effective Date: June 14, 2022</div>*/}
      <div className='terms-content'>
        <p>Edcraze and each Member that provides courses through the edcraze.com care about the confidentiality and security of your information. This Privacy Policy applies to information that Edcraze (a Silda Group LTD) collects through the Edcraze Site when you interact with Edcraze, with Members, with other users, and generally with the Edcraze Site.
        </p>
        <p>Your information is received and controlled by Edcraze according to this Privacy Policy when you sign up for an Edcraze account or otherwise use the Edcraze Site.
        </p>
        <ul>
          <li className='fst-italic'>Edcraze is solely and independently responsible for its own privacy practices. No Member is responsible for Edcraze’s privacy practices.</li>
        </ul>
        <p>If you enroll in a course offered by a Member through the Edcraze Site, information about you and your activity in the course is received and controlled also by that Member as described in this Privacy Policy.</p>
        <ul>
          <li className='fst-italic'>Each Member is solely and independently responsible for its own privacy practices. No Member is responsible for another Member’s privacy practices. Also, Edcraze is not responsible for any Member’s privacy practices.</li>
        </ul>
        <p>If you do not accept the terms of this Privacy Policy, then please do not access, browse, or register for the Edcraze Site or enroll in any courses. If you choose not to provide certain information required to provide you with various products and services offered on the Edcraze Site, then you may not be able to establish a user account or obtain those products or services.
        </p>
        <p>Any version of this Privacy Policy in a language other than English is provided for convenience and you understand and agree that the English language version will control if there is any conflict.
        </p>
        <h4>INFORMATION THAT Edcraze COLLECTS FROM YOU (INCLUDING PERSONAL INFORMATION)
        </h4>
        <p>Edcraze directly collects information when you:
        </p>
        <ul>
          <li>sign up for an Edcraze user account and create an Edcraze user profile;
          </li>
          <li>enroll and participate in online courses;
          </li>
          <li>purchase a product or service ;
          </li>
          <li>send email messages to Edcraze (including messages to learner support);
          </li>
          <li>participate in user surveys; and
          </li>
          <li>participate in public forums, webinars, and other interactive experiences on the Edcraze Site.
          </li>
        </ul>
        <p>Edcraze also indirectly collects usage information about your visit to the Edcraze Site, as explained in the section below titled Cookies and Tracking Technologies.
        </p>
        <p>Edcraze does not itself collect or store financial account numbers or identification issued by a governmental entity, employer, or other authority. Instead, if you choose to make a purchase on the Edcraze Site, for example a Verified Certificate, you will be directed to Edcraze’s third-party payment processor and Edcraze’s third-party ID verification service provider. If you do not wish to submit the required authentication or payment information, then you will not be able to obtain a certificate via the Edcraze Site.
        </p>
        <p>Edcraze requires your Personal Information limited circumstances when you:</p>
        <ul>
          <li>sign up for an Edcraze user account (name, username, email address);
          </li>
          <li>purchase a Verified Certificate (so that Edcraze’s third-party vendor can authenticate your identity) or other product or service (so that Edcraze’s third-party vendor can process payment);
          </li>
          <li>participate in remote proctoring or other course facilitation processes (so that Edcraze’s third-party vendor can monitor and assess the integrity of your course activity); or
          </li>
          <li>earn a Verified Certificate (the certificate will have your name on it).
          </li>
        </ul>
        <p className='fst-italic'>To the extent that Edcraze associates the information that Edcraze collects directly or indirectly with an individual (for example, you), the association is based on Personal Information in your account profile.
        </p>
        <p>You may voluntarily choose to share additional Personal Information on the Edcraze Site, for example:
        </p>
        <ul>
          <li>in your Edcraze account profile (and you may display a limited or full profile to other Edcraze Site users);
          </li>
          <li>in applying for financial assistance; or
          </li>
          <li>when participating in a course, forum, webinar, or other interactive experience on the Edcraze Site (noting that in some cases, this information is shared with a third party that provides tools or other assistance for the Edcraze Site or a course).
          </li>
        </ul>
        <p>Edcraze encourages you to use discretion before voluntarily sharing additional Personal Information on the Edcraze Site. (If you later choose to delete your Edcraze account, deletion of your Personal Information will be subject to the process and limits outlined below.)
        </p>
        <p>Members do not receive your Personal Information unless and until you enroll in a course. If you enroll in a course, the Member that offers the course will receive Personal Information from your Edcraze account profile plus the information that Edcraze collects about your activity and performance in the course in which you enrolled.
        </p>
        <h4>Cookies And Tracking Technologies on the Edcraze Site
        </h4>
        <p>Like many companies, Edcraze uses Cookies and other common Tracking Technologies on the Edcraze Site and in email communications to help Edcraze better understand your use and needs of the Edcraze Site, current and future Edcraze-related products and services, and how Edcraze and Members can improve. For example:
        </p>
        <ul>
          <li>based on your cookie settings, Edcraze tracks information indicating, among other things, which pages of the Edcraze Site were visited, the order in which they were visited, when they were visited, and which hyperlinks and other user interface controls were used;
          </li>
          <li>Edcraze may log the IP address, operating system, and browser software (including mobile use and device type when you use Edcraze native mobile apps) you used when accessing the Edcraze Site, and Edcraze may be able to determine from an IP address your Internet Service Provider and the geographic location of your point of connectivity; and
          </li>
          <li>Edcraze may track and collect information from emails that Edcraze sends to you, for example whether you opened the email or clicked on any links from the email.
          </li>
        </ul>
        <p>Edcraze also partners with third parties to help us track and understand your activity on the Edcraze Site, how you interact with Edcraze’s social media, and how you find or may be referred to the Edcraze Site. Individual Members may separately use Tracking Technologies within their courses and email communications to understand your activity within a course and how you find or may be referred to a course on the Edcraze Site.
        </p>
        <p>Edcraze and Members use Cookies and other Tracking Technologies for the following purposes:
        </p>
        <ul>
          <li>when necessary for system administration, security, and integrity to ensure the Edcraze Site works properly for you. Without these technologies, the Edcraze Site may not function correctly;
          </li>
          <li>to enable analytics, performance and functionality, to help Edcraze and Members gather information about how visitors use the Edcraze Site and monitor Edcraze Site performance, and to enhance your experience by recognizing and remembering whether you have visited the Edcraze Site before and may have any personal preferences.
          </li>
        </ul>
        <p>You have some options for managing Cookies and other Tracking Technologies. These include:
        </p>
        <ul>
          <li><b>Modifying your browser settings:</b> You should be able to control how and whether your web browser accepts Cookies by adjusting its privacy and security settings. The “help” feature of the menu bar on most browsers will tell you how to stop accepting new Cookies, how to receive notification of new Cookies, and how to disable existing Cookies. If you reject Edcraze’s Cookies, many functions and conveniences of the Edcraze Site may not work properly.
          </li>
          <li><b>Opting-out of certain third-party tracking and advertising:</b> Some of the third parties used by Edcraze or a Member for Cookies and Tracking Technologies offer the ability to opt-out from their tracking by following processes described on their websites. A list of some such third parties is maintained and available at the Edcraze learner Help Center and includes, for some, links to applicable opt-out processes. Neither Edcraze nor any Member is responsible for the effectiveness of any third-party opt-out options.
          </li>
        </ul>
        <h4>HOW YOUR INFORMATION IS USED
        </h4>
        <p>Edcraze and Members use information, including Personal Information, to carry out the following purposes:
        </p>
        <ul>
          <li>Operate and improve the Edcraze Site – This includes creating and improving features that support the Edcraze community and enable Edcraze Site usage at scale. This also includes personalizing the Edcraze Site, so your learning experience is tailored to your interests and needs.
          </li>
          <li>Create, administer, provide, and teach courses – This includes creating and improving the delivery of massive open online courses and programs on Edcraze. This also includes personalizing courses, so your learning experience is tailored to your interests and needs, and assessing your performance and awarding certificates.
          </li>
          <li>Offer and improve products and services – This includes enabling you to navigate the Edcraze Site, to enroll and participate in courses and programs on the Edcraze Site, to learn effectively in such courses and programs, and to purchase or obtain products and services on the Edcraze Site such as Verified Certificates.
          </li>
          <li>Develop and maintain the security and performance of the Edcraze Site – This includes tracking Edcraze Site outages and creating software solutions, detecting violations of the Honor Code and Terms of Service, and monitoring uses, misuses, and potential misuses of the Edcraze Site.
          </li>
          <li>Communicate with you – This includes answering your course and platform questions, notifying you of course and Edcraze Site maintenance and updates, marketing to you about course offerings, programs, news, and related products and services of Edcraze or Edcraze affiliates, and as permitted under applicable law, sending you communications about products or services of selected business partners that may be of interest to you.
          </li>
          <li>Support scientific research including, for example, in the areas of cognitive science and education – This includes collaborating to enable and conduct research about how learners access and master course materials online, with the goal of improving course outcomes.
          </li>
          <li>Track Edcraze Site usage against goals and mission – This includes performing analytics to evaluate access to and performance in courses and course-related products and services and to report aggregate usage information (not Personal Information) to business partners and external audiences.
          </li>
          <li>Exercise, enforce, and comply with legal rights and obligations – This includes responding to subpoenas, court orders, or other legal process; and investigating, preventing, or taking action regarding illegal activities, suspected fraud, security or technical issues, or to protect the rights, property, or safety of Edcraze, Members, or others, and as otherwise required by applicable law.
          </li>
        </ul>
        <p>Edcraze or a Member may also seek your consent for additional uses of information, including Personal Information, and will use it only for the purpose described to you. All use of Personal Information is subject to applicable law.
        </p>
        <h4>HOW YOUR INFORMATION IS SHARED
        </h4>
        <p>Edcraze and Members share information, including Personal Information, with third parties for the following purposes:
        </p>
        <ul>
          <li>With service providers, contractors, and other third parties that help carry out the uses described above – Edcraze requires third parties to: (1) abide by this Privacy Policy and applicable law; (2) handle Personal Information in a confidential manner and maintain adequate security; and (3) use Personal Information only as needed to fulfill the relevant purpose(s). In some cases, the Edcraze Site and individual courses are integrated with third-party services or contain links to websites published by third parties, including other content providers as well as service providers. These third parties are responsible for their own privacy practices, so you should pay attention anytime you are redirected to a third-party website and be sure to review its privacy policy.
          </li>
          <li>With other learners in courses that you take and with other visitors to the Edcraze Site, to create an interactive learning environment, support class participation, and share course information – This includes sharing comments, coursework, or other information or content that you submit to a portion of the Edcraze Site designed for viewing by other class members or for public communication. This also includes providing opportunities for you to communicate with other users who may have similar interests or educational goals, for instance, recommending specific study partners or connecting potential student mentees and mentors. In such cases, Edcraze and each Member that offers a course in which you enrolled may use all information collected about you to determine who might be interested in communicating with you, but will only provide others your username (for clarity, no disclosure of your real name or email address).
          </li>
          <li>With employers, government programs, institutions, or other enterprises that sponsor your enrollment in a course for training or other educational purposes – If an entity pays for or otherwise sponsors your Verified Certificate or course participation, Edcraze will share information with the entity as needed to confirm your enrollment, participation, progress, and completion status in that course.
          </li>
          <li>With affiliates of Edcraze or a Member, or with successors in the event of a merger, acquisition, or reorganization, for their use consistent with this Privacy Policy.
          </li>
        </ul>
        <p>Edcraze or a Member may also seek your consent for additional disclosures of information, including Personal Information, and will share it only as described to you. In addition, Edcraze or a Member may share with the public and with third parties, including but not limited to researchers and business partners, information and Personal Information that is de-identified or aggregated in a manner that does not personally identify you.
        </p>
        <h4>HOW TO MANAGE YOUR PERSONAL INFORMATION
        </h4>
        <p>You can manage your Personal Information through learner features on the Edcraze Site and requests to the Edcraze learner support team. If your request involves information controlled by a Member, Edcraze learner support will notify and coordinate with the appropriate Member to address your request. Edcraze will provide support to the extent required by applicable law (for example, learners in the European Union) and more broadly when possible, as a courtesy in our sole discretion. Edcraze will notify you in the event that Edcraze is unable to meet a request that is not legally required. Similarly, Members will only be obligated to comply with requests to the extent legally required, but may choose to comply with other requests in their sole discretion.
        </p>
        <h4>Access and correct your Personal Information
        </h4>
        <p>Much of your Personal Information is available in your Edcraze account. Your name, email address, and other identifiable profile information is editable through the profile settings in your Edcraze account except as noted below. Your account dashboard lists all current and archived courses in which you have enrolled and includes links to any Verified Certificates you may have earned. The dashboard also contains copies of your answers and other participation and performance in courses. Please contact Edcraze learner support to access and update this or other information. If your request involves information controlled by a Member, Edcraze learner support will notify the appropriate Member of your request.
        </p>
        <h4>Restrict or object to the processing of Personal Information
        </h4>
        <p>If you want to manage emails and other communications to you, you may update your preferences in your Edcraze account dashboard, follow the steps described in links at the bottom of email messages, or contact Edcraze learner support. You may object to, or request that Edcraze or a Member stop, its use of your Personal Information for other purposes by contacting the Edcraze learner support team. If your request involves information controlled by a Member, Edcraze learner support will notify the appropriate Member of your request. Please note that if you choose to restrict Edcraze’s or a Member’s ability to process Personal Information, and the processing is otherwise required to provide you with various services and products offered on the Edcraze Site, you may not be able to establish an Edcraze user account or enroll for a course, and Edcraze or a Member may not be able to provide you with those services or products.
        </p>
        <h4>Delete account and Personal Information
        </h4>
        <p>To request deletion of your Edcraze account and Personal Information, you should click the button labeled “Delete my account” in your Edcraze account settings. Because deletion on the Edcraze system is a permanent action and cannot be reversed, Edcraze may ask you to complete a process that aims to confirm your authority to manage the Edcraze account affected by your request.
        </p>
        <p>With respect to your Edcraze account, Edcraze will permanently:
        </p>
        <ul>
          <li>deactivate your Edcraze account,
          </li>
          <li>remove the active profile,
          </li>
          <li>retire your username, and
          </li>
          <li>remove you from Edcraze email lists.
          </li>
        </ul>
        <p>With respect to your Personal Information, Edcraze will permanently delete your Edcraze account profile Personal Information from the Edcraze Site.
        </p>
        <p>As noted above, to the extent that Edcraze associates the information that Edcraze collects directly or indirectly with an individual (for example, you), the association is based on Personal Information in your Edcraze account profile. By erasing the Personal Information in your Edcraze account profile, the remaining information about your activity on the Edcraze Site will no longer be associated with you, except as noted below.
        </p>
        <p>These changes will be applied to data stores that are used for operation of the Edcraze Site including course administration by Members. If you enrolled in any courses on the Edcraze Site, the Edcraze learner support team will share your request with the Members that offered those courses.
        </p>
        <p className='fst-italic'>Exceptions:
        </p>
        <ul>
          <li className='fst-italic'>Deletion will not apply to historical activity logs or archives unless and until these logs and data naturally "age-off" the Edcraze system.
          </li>
          <li className='fst-italic'>Edcraze does not track or index every time or every place on the Edcraze Site when or where a learner may volunteer Personal Information, so neither Edcraze nor Members will be able to help you locate or manage all such instances. Edcraze encourages you to use discretion before voluntarily sharing your Personal Information on the Edcraze Site.
          </li>
          <li className='fst-italic'>Edcraze will archive course data (in a manner and to the extent permitted under applicable law) to serve its mission to enable scientific research on cognitive science and education. These archives are used to produce encrypted research data packages for Members, and Personal Information may not be deleted from research data packages retained by Members.
          </li>
          <li className='fst-italic'>Edcraze and each applicable Member cannot always delete records of past interactions and transactions. For example, records relating to previous purchases on the Edcraze Site must be retained for financial reporting, audit, and compliance reasons.
          </li>
          <li className='fst-italic'>Edcraze and each applicable Member cannot always delete records of past interactions and transactions. For example, records relating to previous purchases on the Edcraze Site must be retained for financial reporting, audit, and compliance reasons.
          </li>
        </ul>
        <h4>Data retention
        </h4>
        <p>Edcraze will retain your Personal Information for as long as your account is active or as needed to provide you with services; to maintain a record of your transactions for financial reporting, audit, and compliance purposes; and to comply with Edcraze’s legal obligations, resolve disputes, enforce its agreements, and as otherwise permitted by applicable law. If you enroll in a Member’s course, such Member will also retain your Personal Information for as long as needed to provide you with services; to maintain a record of your transactions for financial reporting, audit, and compliance purposes; and to comply with its legal obligations, resolve disputes, enforce its agreements, and as otherwise permitted by applicable law. Upon your request that Edcraze deactivate your account and delete your information, Edcraze will follow the process described above, including without limitation archiving your course data (in a manner and to the extent permitted under applicable law) to serve its mission to enable scientific research on cognitive science and education. These archives will be used to produce encrypted research data packages for Members, and each such Member may also keep and use course data for scientific research.
        </p>
        <h4>STORAGE & SECURITY
        </h4>
        <p>Edcraze stores information, including Personal Information, on its own servers and also on servers of companies that Edcraze hires to provide services. Each Member also stores information, including Personal Information, on its own servers and/or on servers of companies that the Member hires to provide services. In each case, information may be stored in the United Arab Emirates and in other countries where Edcraze or a Member operates, and in countries where Edcraze and each Member’s respective service providers operate. If you are in the European Union, Switzerland, or other regions with laws governing data collection and use, you acknowledge that Edcraze and each Member may transfer, process and store your personal information in the United Arab Emirates and other countries, the privacy laws of which may be considered less strict than those of your region.
        </p>
        <p>Edcraze controls its own copy of information collected through the Edcraze Site and has an information security program designed to protect information in its possession or control. This is done through a variety of privacy and security policies, processes, and procedures. Edcraze uses administrative, physical, and technical safeguards that reasonably and appropriately protect the confidentiality, integrity, and availability of the information that it collects, receives, stores, or transmits. Nonetheless, no method of transmission over the Internet, or method of electronic storage, is 100% secure; and therefore, Edcraze cannot guarantee its absolute security. While Edcraze works hard to ensure the integrity and security of its network and systems, Edcraze cannot guarantee that its security measures will prevent "hackers" or other unauthorized persons from illegally accessing or obtaining information.
        </p>
        <p>If Edcraze learns of a security breach involving its copy of your Personal Information, Edcraze may attempt to notify you electronically so that you may take appropriate protective steps. By using the Edcraze Site or providing Personal Information to Edcraze, you agree that Edcraze can communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Edcraze Site. If a security systems breach occurs, Edcraze may post a notice on the Edcraze homepage (www.Edcraze.org) or elsewhere on the Edcraze Site and may send an email to you at the email address associated with your Edcraze account. Depending on where you are located, you may have a legal right to receive notice of a security breach, involving your Personal Information, in writing.
        </p>
        <p>If a Member learns of a security breach involving that Member’s copy of your Personal Information, the Member may attempt to notify you electronically so that you may take appropriate protective steps. By enrolling in a Member’s course on the Edcraze Site or providing Personal Information to the Member, you agree that the Member can communicate with you electronically regarding security, privacy, and administrative issues relating to your course enrollment and participation. If a security systems breach occurs, the affected Member may post a notice on the Edcraze site and/or send an email to you at the email address associated with your enrollment in the Member’s course on the Edcraze Site. Depending on where you are located, you may have a legal right to receive notice of a security breach, involving your Personal Information, in writing.
        </p>
        <h4>Privacy Policy Updates
        </h4>
        <p>This Privacy Policy will be reviewed and updated from time to time. When changes are made, the Privacy Policy will be labeled as "Revised (date)," indicating that you should review the new terms, which will be effective immediately upon posting on this page, with an updated effective date. By accessing the Edcraze Site after any changes have been made, you accept the modified Privacy Policy and any changes contained therein. In case you miss the notification referenced above, be sure to return to this page periodically to ensure familiarity with the most current version of this Privacy Policy.
        </p>
        <h4>Contact Information
        </h4>
        <p>If you have privacy concerns, have disclosed data you would prefer to keep private, or would like to access the Personal Information that Edcraze maintains about you, please contact us at <a
          href="mailto:support@edcraze.com">support@edcraze.com</a>. You may also write to us at: 85 Great Portland Street, First Floor, London, United Kingdom, W1W 7LT. If your request involves a Member, Edcraze will notify the appropriate Member of your request.
        </p>
        <h4>Refund information
        </h4>
        <p> If you have technical problems with the student's panel, please don’t hesitate to contact us, we’ll try to fix this issue. If you would still like a refund or you are requesting the refund for another reason, see the info below.
        </p>
        <p>Once your subscription for the course  starts you can’t get a refund for any payments you’ve already made. If you don’t want to prolong your subscription for any courses, you don’t make payment for any of the courses. You will still have access to the course until the end of the period you have paid for.
        </p>
        <p>Depending on your specific situation, you may be able to return money for the course  within 7 working days of purchase if the course hasn’t started yet.
        </p>
        <h4>Glossary
        </h4>
        <p>Cookies are unique identifiers usually in the form of small data files placed on your device that send certain information about your activity on the Edcraze Site or in an email communication back to Edcraze or the authorized third party that served the cookie. Edcraze or third parties may also use Flash cookies.
        </p>
        <p>Edcraze Site consists of all content and pages located within the Edcraze.org web domain and all Edcraze mobile applications.
        </p>
        <p>Member is each educational institution or other leading global institution or entity that provides courses through the Edcraze Site.
        </p>
        <p>Personal Information is information that specifically identifies you or that, when combined with other information, could be used to identify you.
        </p>
        <p>Tracking Technologies are web beacons, clear gifs, pixels, and similar technologies that are also unique identifiers used to track your online activity but are not stored on your device.
        </p>
        <p>Verified Certificate is a virtual certificate that shows that you have successfully completed your Edcraze course or program and verified your identity using your webcam and your acceptable form of photo ID.
        </p>
        {/*<div className='bold'>Effective Date: June 14, 2022</div>*/}
      </div>
    </>
  );
}

export default Policy;